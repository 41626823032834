export const TOUR_STARTED = 'TOUR_STARTED'
export const TOUR_FINISHED = 'TOUR_FINISHED'
export const TOUR_RESTARTED = 'TOUR_RESTARTED'
export const TOUR_LOADED = 'TOUR_LOADED'
export const TOUR_NEEDS_TO_UPDATE = 'TOUR_NEEDS_TO_UPDATE'

export const FETCH_VEHICLE_DATA_REQUEST = 'FETCH_VEHICLE_DATA_REQUEST'
export const FETCH_VEHICLE_DATA_SUCCESS = 'FETCH_VEHICLE_DATA_SUCCESS'
export const FETCH_VEHICLE_DATA_FAILURE = 'FETCH_VEHICLE_DATA_FAILURE'
export const FETCH_VEHICLE_DATA_EMPTY = 'FETCH_VEHICLE_DATA_EMPTY'

export const FETCH_SERVICE_PLANING_REQUEST = 'FETCH_SERVICE_PLANING_REQUEST'
export const FETCH_SERVICE_PLANING_SUCCESS = 'FETCH_SERVICE_PLANING_SUCCESS'
export const FETCH_SERVICE_PLANING_FAILURE = 'FETCH_SERVICE_PLANING_FAILURE'
export const FETCH_SERVICE_PLANING_EMPTY = 'FETCH_SERVICE_PLANING_EMPTY'

export const FETCH_REPORTS_REQUEST = 'FETCH_REPORTS_REQUEST'
export const FETCH_REPORTS_REQUEST_SUCCESS = 'FETCH_REPORTS_REQUEST_SUCCESS'
export const FETCH_REPORTS_REQUEST_FAILURE = 'FETCH_REPORTS_REQUEST_FAILURE'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_BAD_CREDENTIALS = 'LOGIN_BAD_CREDENTIALS'
export const LOGIN_LOCKED_ACCOUNT = 'LOGIN_LOCKED_ACCOUNT'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGIN_SELECT_STAFF = 'LOGIN_SELECT_STAFF'
export const LOGIN_CANCEL_SELECT_STAFF = 'LOGIN_CANCEL_SELECT_STAFF'

export const BAD_CREDENTIALS_RESOLVED = 'BAD_CREDENTIALS_RESOLVED'

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

export const UNAUTHORIZED = 'UNAUTHORIZED'
export const ACCEPT_FORBIDDEN = 'ACCEPT_FORBIDDEN'
export const FOUND_EXISTING_AUTHENTICATION = 'FOUND_EXISTING_AUTHENTICATION'
export const NO_EXISTING_AUTHENTICATION = 'NO_EXISTING_AUTHENTICATION'
export const EXPIRED_EXISTING_AUTHENTICATION = 'EXPIRED_EXISTING_AUTHENTICATION'

export const BREAK_OUT_OF_RESTRICTED = 'BREAK_OUT_OF_RESTRICTED'
export const CANCEL_BREAK_OUT_OF_RESTRICTED = 'CANCEL_BREAK_OUT_OF_RESTRICTED'

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE'

export const PDF_REQUEST = 'PDF_REQUEST'
export const PDF_SUCCESS = 'PDF_SUCCESS'
export const PDF_FAILURE = 'PDF_FAILURE'
export const PDF_DOWNLOADED = 'PDF_DOWNLOADED'

export const LAUNCH_INFORMATION_UPDATED = 'LAUNCH_INFORMATION_UPDATED'
export const LANGUAGE_LOADED = 'LANGUAGE_LOADED'
export const LANGUAGE_LOADING = 'LANGUAGE_LOADING'

export const FETCH_SUBSCRIPTION_REQUEST = 'FETCH_SUBSCRIPTION_REQUEST'
export const FETCH_SUBSCRIPTION_SUCCESS = 'FETCH_SUBSCRIPTION_SUCCESS'
export const FETCH_SUBSCRIPTION_FAILURE = 'FETCH_SUBSCRIPTION_FAILURE'

export const UPDATE_SUBSCRIPTION_REQUEST = 'UPDATE_SUBSCRIPTION_REQUEST'
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS'
export const UPDATE_SUBSCRIPTION_FAILURE = 'UPDATE_SUBSCRIPTION_FAILURE'

export const FETCH_ALL_ACTIVE_SUBSCRIBERS_REQUEST = 'FETCH_ALL_ACTIVE_SUBSCRIBERS_REQUEST'
export const FETCH_ALL_ACTIVE_SUBSCRIBERS_SUCCESS = 'FETCH_ALL_ACTIVE_SUBSCRIBERS_SUCCESS'
export const FETCH_ALL_ACTIVE_SUBSCRIBERS_FAILURE = 'FETCH_ALL_ACTIVE_SUBSCRIBERS_FAILURE'

export const REMOVE_SUBSCRIPTION_SUCCESS = 'REMOVE_SUBSCRIPTION_SUCCESS'
export const REMOVE_SUBSCRIPTION_FAILURE = 'REMOVE_SUBSCRIPTION_FAILURE'
export const REMOVE_SUBSCRIPTION_REQUEST = 'REMOVE_SUBSCRIPTION_REQUEST'

export const SORT_VEHICLE_DATA_TABLE = 'SORT_VEHICLE_DATA_TABLE'

export const UPDATE_SELECTED_GROUP_FILTER = 'UPDATE_SELECTED_GROUP_FILTER'
