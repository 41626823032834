import moment from 'moment'
import * as types from '../actionTypes'

const initialState = {
  list: {
  },
  loaded: {
  },
  loading: false,
  failure: false,
  lastViewed: undefined,
  order: 'asc',
  orderColumnIndex: 0,
  groupFilter: 'groupFilterAll'
}

const formatReportType = reports => reports.reduce((result, item) => {
  return { ...result, [moment(item.periodStart).format('YYYY-MM-DD')]: item }
}, {})

const formatReports = reports => ({
  week: formatReportType(reports.week),

  month: formatReportType(reports.month),

  year: formatReportType(reports.year)
})

const report = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_REPORTS_REQUEST:
      return { ...state, loading: true }
    case types.FETCH_REPORTS_REQUEST_SUCCESS:
      return { ...state, list: formatReports(action.result), loading: false }
    case types.FETCH_REPORTS_REQUEST_FAILURE:
      return { ...state, list: {}, failure: true, loading: false }
    case types.FETCH_VEHICLE_DATA_REQUEST: {
      const reportsWithPeriodType = state.loaded[action.periodType] || {}
      const reportsWithPeriodStart = reportsWithPeriodType[action.periodStart] || {}
      const reportWithPropulsionType = reportsWithPeriodStart[action.propulsionType] || {}
      const reportWithGroupFilter = reportWithPropulsionType[action.selectedGroupFilter] || {}

      return {
        ...state,
        groupFilter: action.selectedGroupFilter,
        loaded: {
          ...state.loaded,
          [action.periodType]: {
            ...reportsWithPeriodType,
            [action.periodStart]: {
              ...reportsWithPeriodStart,
              [action.propulsionType]: {
                ...reportWithPropulsionType,
                [action.selectedGroupFilter]:
                  { ...reportWithGroupFilter, loading: true, failure: false }
              }
            }
          }
        }
      }
    }
    case types.FETCH_VEHICLE_DATA_SUCCESS: {
      const reportsWithPeriodType = state.loaded[action.periodType] || {}
      const reportsWithPeriodStart = reportsWithPeriodType[action.periodStart] || {}
      const reportWithPropulsionType = reportsWithPeriodStart[action.propulsionType] || {}
      const reportWithGroupFilter = reportWithPropulsionType[action.selectedGroupFilter] || {}

      return {
        ...state,
        groupFilter: action.selectedGroupFilter,
        loaded: {
          ...state.loaded,
          [action.periodType]: {
            ...reportsWithPeriodType,
            [action.periodStart]: {
              ...reportsWithPeriodStart,
              [action.propulsionType]: {
                ...reportWithPropulsionType,
                [action.selectedGroupFilter]: {
                  ...reportWithGroupFilter,
                  loading: false,
                  failure: false,
                  report: action.result
                }
              }
            }
          }
        },
        lastViewed: {
          periodType: action.periodType,
          periodStart: action.periodStart,
          propulsionType: action.propulsionType
        }
      }
    }
    case types.FETCH_VEHICLE_DATA_FAILURE: {
      const reportsWithPeriodType = state.loaded[action.periodType] || {}
      const reportsWithPeriodStart = reportsWithPeriodType[action.periodStart] || {}
      const reportWithPropulsionType = reportsWithPeriodStart[action.propulsionType] || {}
      const reportWithGroupFilter = reportWithPropulsionType[action.selectedGroupFilter] || {}

      return {
        ...state,
        groupFilter: action.selectedGroupFilter,
        loaded: {
          ...state.loaded,
          [action.periodType]: {
            ...reportsWithPeriodType,
            [action.periodStart]: {
              ...reportsWithPeriodStart,
              [action.propulsionType]: {
                ...reportWithPropulsionType,
                [action.selectedGroupFilter]: {
                  ...reportWithGroupFilter,
                  loading: false,
                  failure: true
                }
              }
            }
          }
        }
      }
    }
    case types.FETCH_VEHICLE_DATA_EMPTY: {
      const reportsWithPeriodType = state.loaded[action.periodType] || {}
      const reportsWithPeriodStart = reportsWithPeriodType[action.periodStart] || {}
      const reportWithPropulsionType = reportsWithPeriodStart[action.propulsionType] || {}
      const reportWithGroupFilter = reportWithPropulsionType[action.selectedGroupFilter] || {}

      return {
        ...state,
        groupFilter: action.selectedGroupFilter,
        loaded: {
          ...state.loaded,
          [action.periodType]: {
            ...reportsWithPeriodType,
            [action.periodStart]: {
              ...reportsWithPeriodStart,
              [action.propulsionType]: {
                ...reportWithPropulsionType,
                [action.selectedGroupFilter]: {
                  ...reportWithGroupFilter,
                  loading: false,
                  failure: false,
                  report: {
                    data: null
                  }
                }
              }
            }
          }
        }
      }
    }
    case types.LOGOUT_SUCCESS:
      return initialState
    case types.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        loaded: {},
        failure: false
      }
    case types.LOGIN_SUCCESS:
      return initialState
    case types.SORT_VEHICLE_DATA_TABLE:
      return {
        ...state,
        orderColumnIndex: action.orderColumnIndex,
        order: action.order
      }
    case types.UPDATE_SELECTED_GROUP_FILTER: {
      return {
        ...state,
        groupFilter: action.groupFilter
      }
    }
    default:
      return state
  }
}

export default report
