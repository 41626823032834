/* eslint-disable max-len */
import moment from 'moment'
import authFetch from './authFetch'
import { checkStatus, parseJSON } from '../util/apiUtil'
import { getGasConsumptionType } from '../util/unitTranslationKeys'
import ReportsSchema from '../util/ReportsSchema/ReportsSchema'

const getSystemOfMeasurement = (propulsionConsumptionUnit: PropulsionConsumptionUnit): SystemOfMeasurement =>
  propulsionConsumptionUnit.includes('miles') ? 'imperial' : 'metric'

const getERConsumptionUnit = (propulsionConsumptionUnit: PropulsionConsumptionUnit, propulsionType: PropulsionType): ConsumptionUnitParameter =>
  propulsionType === 'engines' ? 'consumption' : (propulsionConsumptionUnit === 'propper100km' ? 'consumptionper100' : 'distanceperconsumption')

const fetchReportData = (
  periodType: PeriodType,
  startTime: string,
  endTime: string,
  propulsionType: PropulsionType,
  propulsionConsumptionUnit: PropulsionConsumptionUnit,
  gasUnit: GasConsumptionUnit,
  externalEquipmentGroupReference: string,
  yntOffersToggle: boolean,
  templateOptions: ReportsSchemaOptions
) => {
  const options = { headers: {} }
  let propulsionSystem: PropulsionSystemParameter = 'hybrid'
  let equipmentType: EquipmentTypeParameter = 'vehicles'
  const systemOfMeasurement = getSystemOfMeasurement(propulsionConsumptionUnit)
  const consumptionUnitInER = getERConsumptionUnit(propulsionConsumptionUnit, propulsionType)
  const groupFilter = !externalEquipmentGroupReference || externalEquipmentGroupReference === 'groupFilterAll' ? undefined : externalEquipmentGroupReference

  if (propulsionType === 'electric') {
    propulsionSystem = 'electric-motor'
  }
  if (propulsionType === 'engines') {
    equipmentType = 'engines'
    propulsionSystem = 'combustion-engine'
  }

  const gasConsumptionUnit: GasConsumptionUnitParameter = getGasConsumptionType(gasUnit)

  let startTimeInURL = startTime
  let endTimeInURL = endTime

  if (ENV_VAR_APPLICATION !== 'ENVIRONMENTAL') {
    startTimeInURL = moment(startTime).format()
  } else {
    switch (periodType) {
      case 'week': {
        startTimeInURL = startTime + 'T00:00:00Z'
        endTimeInURL = moment(startTime).add(6, 'days').format('YYYY-MM-DD') + 'T23:59:00Z'
        break
      }
      case 'month': {
        startTimeInURL = startTime + 'T00:00:00Z'
        endTimeInURL = moment(startTime).add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD') + 'T23:59:00Z'
        break
      }
      case 'year': {
        startTimeInURL = startTime + 'T00:00:00Z'
        endTimeInURL = moment(startTime).add(1, 'year').subtract(1, 'day').format('YYYY-MM-DD') + 'T23:59:00Z'
        break
      }
      case 'range': {
        startTimeInURL = moment(startTime.replace('Z', '')).format('YYYY-MM-DD HH:mm:ss').replace(' ', 'T') + 'Z'
        endTimeInURL = moment(endTime.replace('Z', '')).format('YYYY-MM-DD HH:mm:ss').replace(' ', 'T') + 'Z'
        break
      }
      default: break
    }
  }

  const { url: urlMonitoringReport, urlEnvironmentalReport } = ReportsSchema.get(propulsionType, templateOptions)

  let url = ENV_VAR_APPLICATION === 'ENVIRONMENTAL' ? urlEnvironmentalReport : urlMonitoringReport

  url = url
    .replace('{BFF_HOST}', ENV_VAR_BFF_HOST)
    .replace('{periodType}', periodType)
    .replace('{startTimeInURL}', startTimeInURL)
    .replace('{endTimeInURL}', endTimeInURL)
    .replace('{propulsionSystem}', propulsionSystem)
    .replace('{gasConsumptionUnit}', gasConsumptionUnit)
    .replace('{propulsionConsumptionUnit}', propulsionConsumptionUnit)
    .replace('{systemOfMeasurement}', systemOfMeasurement)
    .replace('{consumptionUnit}', consumptionUnitInER)
    .replace('{equipmentType}', equipmentType)
    .replace('{yntOffersToggle}', yntOffersToggle)
  
  if (groupFilter && ENV_VAR_APPLICATION === 'ENVIRONMENTAL') {
    url = url + '&externalEquipmentGroupReference=' + groupFilter
  }

  return authFetch(url, options)
    .then(checkStatus)
    .then(parseJSON)
}

// @TODO modify for Environmental Report:
const listReports = (startOfWeek: any, requestDate: moment.Moment) =>
  authFetch(`${ENV_VAR_BFF_HOST}/api/v2/report/list?startOfWeek=${startOfWeek}&requestDate=${requestDate.format('YYYY-MM-DD')}`)
    .then(checkStatus)
    .then(parseJSON)

export default {
  get: (
    periodType: PeriodType,
    startTime: string,
    endTime: string,
    propulsionType: PropulsionType,
    propulsionConsumptionUnit: PropulsionConsumptionUnit,
    gasConsumptionUnit: GasConsumptionUnit,
    yntOffersToggle: boolean,
    externalEquipmentGroupReference: string,
    templateOptions: ReportsSchemaOptions
  ) =>
    fetchReportData(periodType, startTime, endTime, propulsionType, propulsionConsumptionUnit, gasConsumptionUnit, externalEquipmentGroupReference, yntOffersToggle, templateOptions),
  list: (startOfWeek: any) => listReports(startOfWeek, moment())
}
