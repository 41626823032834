// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/angleDown.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GroupsDropdown_groups-dropdown-container-kPl6b{width:256px;margin-left:16px}.GroupsDropdown_groups-dropdown-F2iXv:hover{border:1px solid #dadada;background-color:#eee !important}.GroupsDropdown_groups-dropdown-F2iXv:focus{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") !important;background-repeat:no-repeat !important;background-position:calc(100% - 10px) center !important}\n", "",{"version":3,"sources":["webpack://./src/components/GroupsDropdown.scss"],"names":[],"mappings":"AAAA,gDACE,WAAY,CACZ,gBAAiB,CAClB,4CAGC,wBAAyB,CACzB,gCAAiC,CAClC,4CAGC,mEAA2D,CAC3D,sCAAuC,CACvC,uDAAwD","sourcesContent":[".groups-dropdown-container {\n  width: 256px;\n  margin-left: 16px;\n}\n\n.groups-dropdown:hover {\n  border: 1px solid #dadada;\n  background-color: #eee !important;\n}\n\n.groups-dropdown:focus {\n  background-image: url('../assets/angleDown.svg') !important;\n  background-repeat: no-repeat !important;\n  background-position: calc(100% - 10px) center !important\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"groups-dropdown-container": "GroupsDropdown_groups-dropdown-container-kPl6b",
	"groups-dropdown": "GroupsDropdown_groups-dropdown-F2iXv"
};
export default ___CSS_LOADER_EXPORT___;
