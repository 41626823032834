import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { uuidv4 } from '../util/apiUtil'
import authentication from './authentication'

export const customerGroupsApi = createApi({
  reducerPath: 'customerGroupsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${ENV_VAR_STAFF_HOST}/v1/equipmentgroup`,
    prepareHeaders: async (headers) => {
      const accessToken = await authentication.getValidAccessToken()
      if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`)
      }
      headers.set('X-Correlation-ID', uuidv4())
      headers.set('X-Client', ENV_VAR_X_CLIENT)
      headers.set('content-type', 'application/json')
      return headers
    }
  }),
  endpoints: (builder) => ({
    getCustomerGroupsData: builder.query({
      query: () => ''
    })
  })
})

export const { useGetCustomerGroupsDataQuery } = customerGroupsApi
