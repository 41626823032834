import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'
import ResponsiveDropdownMenu from '../ResponsiveDropdownMenu'
import calendar from '../../assets/calendar.svg'
import preferences from '../../assets/preferences.svg'
import style from './ReportPicker.scss'
import PropulsionTypePicker from '../PropulsionTypePicker'
import ReportPickerButton from './ReportPickerButton'
import i18n from '../../util/localization'
import closeMobile from '../../assets/closeMobile.svg'
import CustomRangePicker from './CustomRangePicker'
import TimePeriodPicker from './TimePeriodPicker'

const getStateFromProps = (props) => {
  const defaultStartDate = moment(props.periodStart + ' 00:00:00').format(
    'YYYY-MM-DD HH:mm:ss'
  )

  if (props.periodType === 'year') {
    return {
      year: props.periodStart,
      reports: props.reports,
      startDate: defaultStartDate,
      endDate:
        moment(defaultStartDate)
          .add(1, 'year')
          .subtract(1, 'day')
          .format('YYYY-MM-DD') + ' 23:59:00',
      propulsionType: props.propulsionType,
      isTimePeriodPicker: false
    }
  }
  if (props.periodType === 'month') {
    return {
      year: moment(props.periodStart).startOf('year').format('YYYY-MM-DD'),
      month: props.periodStart,
      reports: props.reports,
      startDate: defaultStartDate,
      endDate:
        moment(defaultStartDate)
          .add(1, 'month')
          .subtract(1, 'day')
          .format('YYYY-MM-DD') + ' 23:59:00',
      propulsionType: props.propulsionType,
      isTimePeriodPicker: false
    }
  }
  if (props.periodType === 'week') {
    return {
      year: moment(props.periodStart).startOf('year').format('YYYY-MM-DD'),
      month: moment(props.periodStart).startOf('month').format('YYYY-MM-DD'),
      week: moment(props.periodStart).startOf('week').format('YYYY-MM-DD'),
      reports: props.reports,
      startDate: defaultStartDate,
      endDate:
        moment(defaultStartDate).add(6, 'days').format('YYYY-MM-DD') +
        ' 23:59:00',
      propulsionType: props.propulsionType,
      isTimePeriodPicker: false
    }
  }
  if (props.periodType === 'range') {
    return {
      year: moment(props.periodStart).startOf('year').format('YYYY-MM-DD'),
      month: moment(props.periodStart).startOf('month').format('YYYY-MM-DD'),
      week: moment(props.periodStart).startOf('week').format('YYYY-MM-DD'),
      reports: props.reports,
      startDate: props.periodStart,
      endDate: props.periodEnd,
      propulsionType: props.propulsionType,
      isTimePeriodPicker: true
    }
  }
  return {
    periodType: props.periodType,
    reports: props.reports,
    propulsionType: props.propulsionType
  }
}

const reportHasVehicles = (reportCount, propulsionType) =>
  (reportCount[propulsionType] || 0) > 0

class ReportPicker extends React.Component {
  constructor (props) {
    super(props)
    this.state = { ...getStateFromProps(props), isOpen: false }
    this.toggleDropdown = this.toggleDropdown.bind(this)
    this.togglePeriodPicker = this.togglePeriodPicker.bind(this)
    this.setPropulsionType = this.setPropulsionType.bind(this)
    this.getSelectedPeriodType = this.getSelectedPeriodType.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  setPropulsionType (type) {
    const periodType = this.getSelectedPeriodType()
    let hasNewPropulsionTypeData = false
    switch (periodType) {
      case 'year':
        hasNewPropulsionTypeData = this.props.reports.year[this.state.year]
          ? reportHasVehicles(
            this.props.reports.year[this.state.year].reportCount,
            type
          )
          : false
        break
      case 'month':
        hasNewPropulsionTypeData = this.props.reports.month[this.state.month]
          ? reportHasVehicles(
            this.props.reports.month[this.state.month].reportCount,
            type
          )
          : false
        break
      default:
        hasNewPropulsionTypeData = this.props.reports.week[this.state.week]
          ? reportHasVehicles(
            this.props.reports.week[this.state.week].reportCount,
            type
          )
          : false
        break
    }

    if (hasNewPropulsionTypeData) {
      this.setState({
        propulsionType: type
      })
    } else {
      this.setState({
        propulsionType: type,
        year: null,
        month: null,
        week: null
      })
    }
  }

  getSelectedPeriodType () {
    let periodType = 'year'
    if (this.state.week) {
      periodType = 'week'
    } else if (this.state.month) {
      periodType = 'month'
    } else if (this.state.range) {
      periodType = 'range'
    }
    return periodType
  }

  handleClickOutside (event) {
    if (event?.target?.nodeName !== 'HTML') {
      this.setState({
        isOpen: false
      })
    }
  }

  toggleDropdown () {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  togglePeriodPicker () {
    this.setState({
      isTimePeriodPicker: !this.state.isTimePeriodPicker
    })
    if (!this.state.isTimePeriodPicker) {
      this.setState({ periodType: 'range' })
    } else {
      this.setState({ periodType: this.props.periodType })
    }
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    this.setState(getStateFromProps(newProps))
  }

  render () {
    return (
      <>
        <div className={`dropdown ${style.dropdown}`}>
          {this.state.isOpen || (
            <ReportPickerButton
              onClick={this.toggleDropdown}
              disabled={this.props.disabled}
              periodStart={this.props.periodStart}
              periodEnd={this.props.periodEnd}
              periodType={this.props.periodType}
              propulsionType={this.props.propulsionType}
            />
          )}
          {this.state.isOpen && (
            <ResponsiveDropdownMenu>
              <div
                className={`container ${style['report-picker-dropdown-content']}`}
              >
                <div className={`row ${style['report-picker-row']}`}>
                  <div className="col-xs-10">
                    <span className={style['report-picker-header-icon']}>
                      <img src={calendar} alt="calendar" />
                    </span>
                    <span className={style['report-picker-header-icon']}>
                      <img src={preferences} alt="preferences" />
                    </span>
                    <span className={style['report-picker-header-label']}>
                      <h5>{i18n.t('VäljRapport')}</h5>
                    </span>
                  </div>
                  <div className="col-xs-2">
                    <button
                      type="button"
                      onClick={this.toggleDropdown}
                      className="close"
                    >
                      <span>
                        <img
                          alt="close"
                          height="28px"
                          width="28px"
                          src={closeMobile}
                        />
                      </span>
                      <span className="sr-only">{i18n.t('Stäng')}</span>
                    </button>
                  </div>
                </div>
                <div className={`row ${style['report-picker-row']}`}>
                  <div className="d-flex col-sm-12 col-xs-12">
                    <PropulsionTypePicker
                      propulsionType={this.state.propulsionType}
                      callback={this.setPropulsionType}
                      reports={this.props.reports}
                      ReactGA={this.props.ReactGA}
                    />
                    {ENV_VAR_APPLICATION === 'ENVIRONMENTAL' && (
                      <>
                        <button
                          type="button"
                          className={`btn btn-primary ${style.togglePeriodPickerBtn}`}
                          onClick={this.togglePeriodPicker}
                          disabled={!this.state.isTimePeriodPicker}
                        >
                          {i18n.t('Tidsperiod')}
                        </button>
                        <button
                          type="button"
                          className={`btn btn-primary ${style.togglePeriodPickerBtn}`}
                          onClick={this.togglePeriodPicker}
                          disabled={this.state.isTimePeriodPicker}
                        >
                          {i18n.t('AnpassatIntervall')}
                        </button>
                      </>
                    )}
                  </div>
                </div>

                {!this.state.isTimePeriodPicker
                  ? (
                  <TimePeriodPicker
                    periodType={this.props.periodType}
                    periodStart={this.state.startDate}
                    year={this.state.year}
                    month={this.state.month}
                    week={this.state.week}
                    reports={this.state.reports}
                    propulsionType={this.state.propulsionType}
                    ReactGA={this.props.ReactGA}
                    toggleDropdown={this.toggleDropdown}
                    handleDateChange={(newState) => this.setState(newState)}
                  />
                    )
                  : (
                  <CustomRangePicker
                    handleDateChange={(newState) => { this.setState(newState) }}
                    periodStart={this.state.startDate}
                    periodEnd={this.state.endDate}
                    propulsionType={this.state.propulsionType}
                    toggleDropdown={this.toggleDropdown}
                  />
                    )}
              </div>
            </ResponsiveDropdownMenu>
          )}
        </div>
      </>
    )
  }
}

ReportPicker.propTypes = {
  periodStart: PropTypes.string.isRequired,
  periodEnd: PropTypes.string,
  periodType: PropTypes.string.isRequired,
  propulsionType: PropTypes.string.isRequired,
  reports: PropTypes.shape({
    week: PropTypes.shape({}),
    month: PropTypes.shape({}),
    year: PropTypes.shape({})
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  ReactGA: PropTypes.shape().isRequired
}

export default onClickOutside(ReportPicker)
