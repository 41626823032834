import { combineReducers } from 'redux'
import report from './report/reducer'
import servicePlans from './servicePlaning/reducer'
import authentication from './authentication/reducer'
import user from './user/reducer'
import pdf from './pdf/reducer'
import launchInformation from './launchInformation/reducer'
import language from './language/reducer'
import authorization from './authorization/reducer'
import subscription from './userSubscription/reducer'
import staff from './staff/reducer'
import tour from './tour/reducer'
import { fmpTokenApi } from '../api/SSO'
import { cycledEnergyApi } from '../api/cycledEnergy'
import { customerGroupsApi } from '../api/groups'

const reducers = combineReducers({
  [fmpTokenApi.reducerPath]: fmpTokenApi.reducer,
  [cycledEnergyApi.reducerPath]: cycledEnergyApi.reducer,
  [customerGroupsApi.reducerPath]: customerGroupsApi.reducer,
  report,
  servicePlans,
  authentication,
  user,
  pdf,
  launchInformation,
  language,
  authorization,
  subscription,
  staff,
  tour
})

export default reducers
