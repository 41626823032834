export const getReport = (state, periodType, periodStart, propulsionType, selectedGroupRef = 'groupFilterAll') => {
  if (state.report.loaded[periodType] && state.report.loaded[periodType][periodStart]) {
    if (state.report.loaded[periodType][periodStart][propulsionType]) {
      return state.report
        .loaded[periodType][periodStart][propulsionType][selectedGroupRef]
    }
  }

  return null
}

export const getReportData = (state, periodType, periodStart, propulsionType, selectedGroupRef = 'groupFilterAll') => {
  const result = getReport(state, periodType, periodStart, propulsionType, selectedGroupRef)
  if (result) {
    return result.report || null
  }
  return null
}

export const getReports = state =>
  state.report.list

export const isLoadingReports = state => state.report.loading
export const hasLoadedReports = state => Object.keys(state.report.list || {}).length > 0
export const hasLoadingReportsFailure = state => state.report.failure

export const isLoadingReport = (state, periodType, periodStart, propulsionType, selectedGroupRef = 'groupFilterAll') => {
  const result = getReport(state, periodType, periodStart, propulsionType, selectedGroupRef)
  if (result) {
    return result.loading || false
  }
  return false
}

export const hasAccessToReport = (state, periodType, periodStart) => {
  if (state.report.list[periodType]) {
    if (state.report.list[periodType][periodStart]) {
      return state.report.list[periodType][periodStart].access
    }
  }
  return false
}

export const hasLoadingReportfailure = (state, periodType, periodStart, propulsionType, selectedGroupRef = 'groupFilterAll') => {
  const result = getReport(state, periodType, periodStart, propulsionType, selectedGroupRef)

  if (result) {
    return result.failure || false
  }
  return false
}

export const hasLoadedReport = (state, periodType, periodStart, propulsionType, selectedGroupRef = 'groupFilterAll') =>
  (getReportData(state, periodType, periodStart, propulsionType, selectedGroupRef) !== null)

export const isEmptyReport = (state, periodType, periodStart, propulsionType, selectedGroupRef = 'groupFilterAll') =>
  ((getReportData(state, periodType, periodStart, propulsionType, selectedGroupRef) || {}).data == null)

export const getLastViewed = state => state.report.lastViewed

export const getPreviousPeriod = (state, periodType, periodStart) => {
  if (state.report.list[periodType]) {
    if (state.report.list[periodType][periodStart]) {
      const periods = Object.keys(state.report.list[periodType]).sort()
      const indexOfPrevious = periods.indexOf(periodStart) - 1
      if (indexOfPrevious >= 0) {
        return periods[indexOfPrevious]
      }
    }
  }
  return null
}

export const getVehicleDataTableOrder = (state) => ({ order: state.report?.order, orderColumnIndex: state.report?.orderColumnIndex })

export const getSelectedGroup = (state) => (state?.report?.groupFilter || 'groupFilterAll')
