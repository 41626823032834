import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { fmpTokenApi } from '../api/SSO'
import { cycledEnergyApi } from '../api/cycledEnergy'
import { customerGroupsApi } from '../api/groups'
import { createLogger } from 'redux-logger'
import reducer from './reducers'
import crashReporter from '../util/crashReporter'
import analyticsEventReporter from '../util/analyticsEventReporter'

const getMiddleware = ({ context = 'prod' } = {}) => getDefaultMiddleware => {
  const middleware = getDefaultMiddleware()
    .concat(fmpTokenApi.middleware)
    .concat(cycledEnergyApi.middleware)
    .concat(customerGroupsApi.middleware)
  if (!context === 'test') {
    middleware
      .concat(crashReporter)
      .concat(analyticsEventReporter)
  }
  if (ENV_VAR_DEV_MODE) {
    middleware.concat(createLogger())
  }
  return middleware
}

const getStore = (preloadedState = {}, middleware = getMiddleware()) => {
  const store = configureStore({
    reducer,
    preloadedState,
    middleware
  })

  setupListeners(store.dispatch)
  return store
}

export const defaultStore = getStore()

export default getStore
export { getMiddleware }
