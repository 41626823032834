import { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { getReport } from '../../state/report/actions'
import { isLoadingReport, hasLoadedReport, hasLoadingReportfailure } from '../../state/report/selectors'
import userSelectors from '../../state/user/selectors'

interface QueryReportProps {
  loading: any,
  failure: any,
  alreadyLoaded: boolean,
  periodType: PeriodType,
  periodStart: string,
  periodEnd: string,
  propulsionType: PropulsionType,
  units: PropulsionConsumptionUnit,
  gasUnits: GasConsumptionUnitParameter,
  externalEquipmentGroupReference: string,
  getReport: any
}

const QueryReport = ({
  loading,
  failure,
  alreadyLoaded,
  periodType,
  periodStart,
  periodEnd,
  propulsionType,
  units,
  gasUnits,
  externalEquipmentGroupReference,
  getReport
}: QueryReportProps) => {
  const { showYntProposals } = useFlags()
  const showUnstableHybridData = true
  const country = useSelector(userSelectors.getCustomerCountry)
  const vehicleIdentifier = useSelector(userSelectors.getVehicleIdentifier)

  useEffect(() => {
    if (!loading &&
          !failure &&
          !alreadyLoaded &&
          periodType &&
          periodStart &&
          propulsionType &&
          units &&
          gasUnits) {
      const shouldHideAdBlueData = country?.toLowerCase() === 'gb'

      getReport(
        periodType,
        periodStart,
        periodEnd,
        propulsionType,
        units,
        gasUnits,
        vehicleIdentifier,
        showYntProposals,
        externalEquipmentGroupReference,
        { showUnstableHybridData, hideAdBlueData: shouldHideAdBlueData }
      )
    }
  }, [loading, failure, alreadyLoaded, periodType, periodStart, periodEnd, propulsionType, units, gasUnits, showYntProposals, showUnstableHybridData, getReport, externalEquipmentGroupReference])

  return null
}

const mapStateToProps = (state, ownProps) => ({
  loading: isLoadingReport(
    state,
    ownProps.periodType,
    ownProps.periodStart,
    ownProps.propulsionType,
    ownProps.externalEquipmentGroupReference
  ),
  failure: hasLoadingReportfailure(
    state,
    ownProps.periodType,
    ownProps.periodStart,
    ownProps.propulsionType,
    ownProps.externalEquipmentGroupReference
  ),
  alreadyLoaded: hasLoadedReport(
    state,
    ownProps.periodType,
    ownProps.periodStart,
    ownProps.propulsionType,
    ownProps.externalEquipmentGroupReference
  ),
  isSavingProfile: userSelectors.isSavingProfile(state)
})

const mapDispatchToProps = dispatch => ({
  getReport (type: PeriodType, startOfPeriod: string, endOfPeriod: string, propulsionType: PropulsionType, units: any, gasUnits: GasConsumptionUnit, vehicleIdentifier: string, yntOffersToggle: boolean, externalEquipmentGroupReference: string, templateOptions: any) {
    dispatch(getReport(type, startOfPeriod, endOfPeriod, propulsionType, units, gasUnits, vehicleIdentifier, yntOffersToggle, externalEquipmentGroupReference, templateOptions))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(QueryReport)
