import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import userSelectors from '../state/user/selectors'
import { getExtStaffRef } from '../state/authentication/selectors'
import { getReports, hasLoadedReports } from '../state/report/selectors'
import QueryStaff from '../components/data/QueryStaff'
import ListReports from '../components/data/ListReports'
import CompanyHeader from '../components/CompanyHeader'
import ReportPicker from '../components/ReportPicker/ReportPicker'
import userActions from '../state/user/actions'
import ExportContainer from './ExportContainer'
import GroupsDropdown from '../components/GroupsDropdown'

const ReportHeaderContainer = ({
  extStaffRef, customerName, periodType, periodStart, periodEnd = null, propulsionType,
  reports, restricted, loadedReports, startOfWeek, ReactGA, setSelectedGroupFilter
}) => (
  <div>
    <QueryStaff extStaffRef={extStaffRef} />
    <ListReports startOfWeek={startOfWeek} />
    <div className="row ">
      <div className="col-sm-12 report-title-band">
        <CompanyHeader companyName={customerName} />
        <span className="pull-right">
          <ExportContainer
            periodType={periodType}
            periodStart={periodStart}
            periodEnd={periodEnd}
            propulsionType={propulsionType}
            ReactGA={ReactGA}
          />

        </span>
      </div>
      <div className="col-md-12 col-xs-12 report-picker-band">
        <ReportPicker
          periodType={periodType}
          periodStart={periodStart}
          periodEnd={periodEnd}
          reports={reports}
          disabled={!loadedReports}
          propulsionType={propulsionType}
          restricted={restricted}
          ReactGA={ReactGA}
        />
        { ENV_VAR_APPLICATION === 'ENVIRONMENTAL' &&
          <GroupsDropdown updateCustomerGroup={setSelectedGroupFilter} />
        }
      </div>
    </div>
  </div>
)

ReportHeaderContainer.propTypes = {
  extStaffRef: PropTypes.string,
  customerName: PropTypes.string,
  periodType: PropTypes.string.isRequired,
  periodStart: PropTypes.string.isRequired,
  periodEnd: PropTypes.string,
  propulsionType: PropTypes.string.isRequired,
  reports: PropTypes.shape({}),
  restricted: PropTypes.bool.isRequired,
  startOfWeek: PropTypes.string,
  loadedReports: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.string.isRequired,
      start: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      propulsionType: PropTypes.string.isRequired
    }).isRequired
  }),
  ReactGA: PropTypes.shape().isRequired,
  setSelectedGroupFilter: PropTypes.func
}

ReportHeaderContainer.defaultProps = {
  extStaffRef: null,
  customerName: null,
  startOfWeek: null,
  reports: {},
  match: null
}

const mapStateToProps = state => ({
  customerName: userSelectors.getCustomerName(state),
  extStaffRef: getExtStaffRef(state),
  reports: getReports(state),
  loadedReports: hasLoadedReports(state),
  startOfWeek: userSelectors.getStartOfWeek(state),
  restricted: state.authentication.user.permissions.restricted
})

const mapDispatchToProps = dispatch => ({
  updateProfileUnits (unit) {
    dispatch(userActions.updateLoggedInUserProfile({ unit }))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportHeaderContainer)
