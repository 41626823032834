import reportDataApi from '../../api/reportData'
import * as types from '../actionTypes'
import { transformDataForEnvironmentalReport } from '../../util/environmentalReportDataTransformer'

export const sortVehicleDataTable = (orderColumnIndex: number, order: 'asc' | 'desc') => ({
  type: types.SORT_VEHICLE_DATA_TABLE,
  orderColumnIndex,
  order
})

export const updateSelectedGroupFilter = (groupFilter: string) => ({
  type: types.UPDATE_SELECTED_GROUP_FILTER,
  groupFilter
})

function fetchVehicleDataRequest (periodType, periodStart, periodEnd, propulsionType, selectedGroupFilter) {
  return {
    type: types.FETCH_VEHICLE_DATA_REQUEST,
    periodType,
    periodStart,
    periodEnd,
    propulsionType,
    selectedGroupFilter
  }
}

function fetchVehicleDataSuccess (vehicleDataResult, periodType, periodStart, periodEnd, propulsionType, selectedGroupFilter) {
  return {
    type: types.FETCH_VEHICLE_DATA_SUCCESS,
    result: vehicleDataResult,
    periodType,
    periodStart,
    periodEnd,
    propulsionType,
    selectedGroupFilter
  }
}

function fetchVehicleDataFailure (error, periodType, periodStart, periodEnd, propulsionType, selectedGroupFilter) {
  return {
    type: types.FETCH_VEHICLE_DATA_FAILURE,
    error,
    periodType,
    periodStart,
    periodEnd,
    propulsionType,
    selectedGroupFilter
  }
}

function fetchReports () {
  return {
    type: types.FETCH_REPORTS_REQUEST
  }
}

function fetchReportsSuccess (reportList) {
  return {
    type: types.FETCH_REPORTS_REQUEST_SUCCESS,
    result: reportList
  }
}

function fetchReportsFailure (error) {
  return {
    type: types.FETCH_REPORTS_REQUEST_FAILURE,
    error: error.message
  }
}

function fetchVehicleDataEmpty (error, periodType, periodStart, periodEnd, propulsionType, selectedGroupFilter) {
  return {
    type: types.FETCH_VEHICLE_DATA_EMPTY,
    error,
    periodType,
    periodStart,
    periodEnd,
    propulsionType,
    selectedGroupFilter
  }
}

const isEmtpyReport = error => error.status === 404

export const listReports = startOfWeek => (dispatch) => {
  dispatch(fetchReports())
  return reportDataApi
    .list(startOfWeek)
    .then(
      reportListResult => dispatch(fetchReportsSuccess(reportListResult)),
      error => dispatch(fetchReportsFailure(error.message))
    )
}

export const getReport = (periodType: PeriodType, periodStart: any, periodEnd: any,
  propulsionType: PropulsionType, units: PropulsionConsumptionUnit, gasUnits: GasConsumptionUnit,
  vehicleIdentifier: string, yntOffersToggle: boolean, externalEquipmentGroupReference: string = 'groupFilterAll',
  templateOptions: ReportsSchemaOptions) => (dispatch: (arg0: { type: string; periodType: any; periodStart: any; periodEnd: any; propulsionType: any; result?: any; error?: any }) => void) => {
  dispatch(fetchVehicleDataRequest(periodType, periodStart, periodEnd, propulsionType, externalEquipmentGroupReference))

  return reportDataApi
    .get(periodType, periodStart, periodEnd, propulsionType, units, gasUnits, yntOffersToggle, externalEquipmentGroupReference, templateOptions)
    .then((vehicleDataResult: any) => {
      const applicationReport = transformDataForEnvironmentalReport(vehicleDataResult, propulsionType, vehicleIdentifier)
      dispatch(fetchVehicleDataSuccess(applicationReport, periodType, periodStart, periodEnd, propulsionType, externalEquipmentGroupReference))
    })
    .catch((error: any) => {
      if (isEmtpyReport(error)) {
        dispatch(fetchVehicleDataEmpty(error, periodType, periodStart, periodEnd, propulsionType, externalEquipmentGroupReference))
      }
      dispatch(fetchVehicleDataFailure((error.message || 'Failed to load vehicle data'), periodType, periodStart, periodEnd, propulsionType, externalEquipmentGroupReference))
    })
}
